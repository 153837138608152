<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-top">
        <!-- <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="addMessage">新增</el-button> -->
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="手机号">
              <el-input v-model="logisticsForm.tel" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="用户名">
              <el-input v-model="logisticsForm.userName" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="路径">
              <el-input v-model="logisticsForm.path" placeholder="请输入路径"></el-input>
            </el-form-item>
            <el-form-item label="绑定单号">
              <el-input v-model="logisticsForm.orderNo" placeholder="请输入绑定单号"></el-input>
            </el-form-item>
            <el-form-item style="margin-left:2vh">
              <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip align="center"> </el-table-column>
      <el-table-column prop="userName" label="用户名" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="tel" label="手机号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="days" label="有效期限" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="dpmc" label="大屏名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="path" label="路径" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="orderNo" label="绑定单号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="amount" label="金额" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" show-overflow-tooltip align="center"></el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[8, 10, 50, 100, 150]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                tableData: [],
                multipleSelection: [],
                logisticsForm: {
                    userName: '',
                    tel:'',
                    path: '',
                    orderNo: ''
                },
                page: 1,
                size: 10,
                listTotal: 0,
                loading: false,
                searchStatus: false,
            };
        },
        mounted() {
            this.requrstList();
        },
        methods: {
            //请求列表数据
            requrstList() {
              this.loading = true
                this.$get('/userManage/pageOrder', {
                    page: this.page,
                    size: this.size,
                    ...this.logisticsForm
                }).then(
                    (res) => {
                        if (res.data.state == 'success') {
                            this.listTotal = res.data.count;
                            this.tableData = res.data.data;
                        }
                        this.loading = false;
                    }
                );
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            //搜索
            search() {
                this.page = 1;
                this.searchStatus = true;
                this.requrstList();
            },
            handleSizeChange(val) {
                this.size = val;
                this.requrstList();
                //console.log(val);
                },
             handleCurrentChange(val) {
                this.page = val;
                this.requrstList();
             },
        },
    };
</script>

<style scoped lang="less">
    @import '@/style/backTable.less';
    .operation {
        display: flex;
    }
    
    .newSeeds /deep/ .el-table td,
    .el-table th {
        padding: 1.11vh 0;
    }
    
    .title {
        height: 6vh;
        overflow: hidden;
    }
    
    .newSeeds {
        max-height: 91vh;
        overflow-y: auto;
    }
</style>